import React, { useRef, useEffect, useState, MutableRefObject } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { RootState, AppDispatch } from './store';
import { selectEvent, IssueSummaryDetailType, EventSubtypeStatus, EventFields } from '../slices/eventSlice';
import { selectUserProfile } from '../slices/userProfileSlice';
import { selectIssueSummary } from '../slices/issueSummarySlice';
import { formatDefaultDisplay } from '../helper/dateHelper';
import {
    getItemsFromResourceList,
    MappingKey,
    resourceCheck,
    ResourceType,
    Permission,
    isMobileOrTablet,
} from '../helper/resourceVerifyHelper';
import ErrorPage403 from '../pages/error/ErrorPage403';
import { getTimeOffsetByPortThunk, selectCommon } from '../slices/commonSlice';
import { DateTime, FixedOffsetZone } from 'luxon';
import FlightBar, { ButtonTypes, IFlightBar } from '../components/common/FlightBarInfo';
import { RelatedActivityType } from '../constants/constants';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDescription = (): string => {
    const { issueSummaryInfo } = useAppSelector(selectIssueSummary);
    const { details: issueSummaryDetails }: { details?: IssueSummaryDetailType } = issueSummaryInfo || {};
    const { description: issueSummaryDescription } = issueSummaryDetails || {};

    const { selectedEvent } = useAppSelector(selectEvent);
    const { description: eventDescription } = selectedEvent || {};

    return issueSummaryDescription === null || issueSummaryDescription === undefined
        ? eventDescription || '--'
        : issueSummaryDescription || '--';
};

export const useTimestamp = (): string => {
    const { selectedEvent } = useAppSelector(selectEvent);
    const { subtypeStatus, atsTime, etsTime: eventEtsTime } = selectedEvent || {};
    const { issueSummaryInfo } = useAppSelector(selectIssueSummary);
    const { etsTime: issueSummaryEtsTime } = issueSummaryInfo || {};

    const isServiceable = subtypeStatus === EventSubtypeStatus.serviceable;

    if (isServiceable) {
        return `ATS ${formatDefaultDisplay(atsTime) || '--'}`;
    }

    const timeDisplay = formatDefaultDisplay(
        issueSummaryEtsTime && issueSummaryEtsTime !== '--' ? issueSummaryEtsTime : eventEtsTime
    );

    return `ETS forecast of ${timeDisplay}`;
};

export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useScrollPosition = (targetElementRef: MutableRefObject<HTMLElement>): number => {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const scrollHandler = (e: Event) => {
            if (!e.target) return;

            try {
                const elementTarget = e.target as Element;
                setPosition(elementTarget.scrollTop);
            } catch (err) {
                return;
            }
        };
        targetElementRef.current?.addEventListener('scroll', scrollHandler);

        return () => {
            targetElementRef.current?.removeEventListener('scroll', scrollHandler);
        };
    }, [targetElementRef]);

    return position;
};

/**
 * This hook is used to manage the open state for a list of generic items and update function.
 *
 * @param list    array of object in any type.
 * @param keyPath the path for the unique for to identify the object.
 * e.g {a: {b: {c: 'keyValue'}}}
 * To get keyValue as unique identifier. Pass 'a.b.c'. Will get 'keyValue'
 *
 * @returns {openState, isAllExpand, hasExpand, toggleAll, toggleItem}
 */

export const useOpenState = (list: any[], keyPath: string, resetState?: boolean) => {
    const previousList = usePrevious(list);
    const [openState, setOpenState] = useState<{ [key: string]: boolean }>({});
    const keyPathSplit = keyPath.split('.');
    const isAllExpand =
        list.length > 0
            ? Object.keys(openState)?.length !== 0 && Object.keys(openState).every((key) => openState[key] === true)
            : false;
    const hasExpand = list.length > 0 ? Object.keys(openState).some((key) => openState[key] === true) : false;

    const getValueByKey = (target: Object | string | number, keyPathIndex: number) => {
        const mTarget = target[keyPathSplit[keyPathIndex]];
        if (typeof mTarget !== 'object') return mTarget;

        return getValueByKey(mTarget, keyPathIndex + 1);
    };

    const toggleAll = (isOpen?: boolean) => {
        const hasIsOpen = typeof isOpen === 'boolean';
        const newState = Object.keys(openState).reduce((acc, cur) => {
            acc[cur] = hasIsOpen ? isOpen : !openState[cur];
            return acc;
        }, {});
        setOpenState(newState);
    };

    const toggleItem = (target: string, isOpen?: boolean) => {
        const hasIsOpen = typeof isOpen === 'boolean';
        setOpenState((pre) => ({
            ...pre,
            [target]: hasIsOpen ? isOpen : !pre[target],
        }));
    };

    useEffect(() => {
        if (list && list.length > 0) {
            if (!isEqual(previousList, list) || resetState) {
                const openStateObj = list.reduce((acc, cur) => {
                    const key = getValueByKey(cur, 0);
                    acc[key] = resetState ? false : openState[key] || false;
                    return acc;
                }, {});
                setOpenState(openStateObj);
            }
        }
    }, [list]);

    return { openState, isAllExpand, hasExpand, toggleAll, toggleItem };
};

export const useRoutes = () => {
    const history = useHistory();
    const isMobileOrTablet = useMobileOrTablet();
    const { userProfile } = useAppSelector(selectUserProfile);
    const { currentPermissionList } = userProfile || {};
    const viewTypeResourceList =
        currentPermissionList?.filter(
            (item) => item.resourceType === ResourceType.VIEW || item.resourceType === ResourceType.MOBILETABLET
        ) || [];

    const currentHomePage = () => {
        const homePagePermission = getItemsFromResourceList(currentPermissionList, MappingKey.HOME_PAGE);
        const homePage = homePagePermission?.[0];
        return homePage;
    };

    const redirectToDefaultHomePage = () => {
        if (!history) throw new Error("'history' is invalid. 'history' has to be used in child component of 'Router'.");

        const homePage = currentHomePage();
        history.replace(homePage);
    };

    const canAccessPage = (path: string, component: React.FC) => {
        const deniedPage = ErrorPage403;
        if (!path || !component) {
            return deniedPage;
        }

        if (isMobileOrTablet) {
            const isMobileOrTabletPage = resourceCheck(viewTypeResourceList, ResourceType.MOBILETABLET, path);
            if (isMobileOrTabletPage) {
                return component;
            }
        } else if (resourceCheck(viewTypeResourceList, ResourceType.VIEW, path)) {
            return component;
        }

        return deniedPage;
    };

    return { currentHomePage, redirectToDefaultHomePage, canAccessPage };
};

export const useDrawer = () => {
    const { userProfile } = useAppSelector(selectUserProfile);
    const { name, currentGroupName, currentPermissionList } = userProfile || {};
    const isMobileOrTablet = useMobileOrTablet();

    const drawItemKeyList = getItemsFromResourceList(currentPermissionList, MappingKey.ROUTER, isMobileOrTablet);
    return { name, currentGroupName, drawItemKeyList };
};

export const usePermission = () => {
    const { userProfile } = useAppSelector(selectUserProfile);
    const { currentPermissionList } = userProfile || {};

    const checkResource = (resource: Permission) => {
        const { resourceType, resourceName, resourceAction } = resource;
        return resourceCheck(currentPermissionList, resourceType, resourceName, resourceAction);
    };

    const permissionCheck = ((resources: Permission | Permission[]): boolean | boolean[] => {
        if (Array.isArray(resources)) {
            return resources.map((item) => checkResource(item));
        }
        return checkResource(resources);
    }) as ((resources: Permission) => boolean) & ((resources: Permission[]) => boolean[]);

    return { permissionCheck };
};

export const useMobileOrTablet = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const isIncluded = isMobileOrTablet();
        setIsMobile(isIncluded);
    }, []);

    return isMobile;
};

export const useLocalTime = () => {
    const dispatch = useAppDispatch();
    const { portTimeOffsets } = useAppSelector(selectCommon);

    const getTimeOffsetByPort = async (port: string, flightdateLocal: string): Promise<number | null> => {
        if (!port) return null;
        try {
            const upperCasePort = `${port.toUpperCase()}|${flightdateLocal}`;
            if (!(upperCasePort in portTimeOffsets)) {
                // TODO: define a proper request payload
                // [IOCTEM-5663] IOC Alert Day Light Saving
                const offsets = await dispatch(getTimeOffsetByPortThunk([upperCasePort]))
                .unwrap();
                return offsets[upperCasePort];
            }

            return portTimeOffsets[upperCasePort];
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const convertLocalTime = async (port: string, dateTimes: string[]): Promise<string[]> => {
        if (!port) return new Array(dateTimes.length).fill('');

        return await dateTimes.reduce(async (acc, cur) => {
            const accResults = await acc;

            if (!cur) return [...accResults, ''];

            const timeOffset = await getTimeOffsetByPort(port, cur);
            const zone = FixedOffsetZone.instance(timeOffset);
            if (timeOffset === null || timeOffset === undefined || !zone.isValid) {
                return [...accResults, ''];
            } else {
                return [...accResults, DateTime.fromISO(cur, { zone: zone.name }).toISO()];
            }
        }, Promise.resolve([]));
    };

    return { convertLocalTime, portTimeOffsets };
};
export const useFlightBar = ({
    type,
    details,
    displayButtons,
}: {
    type: RelatedActivityType;
    details: IFlightBar;
    displayButtons?: {
        button: ButtonTypes;
        onClickAction: (ufi: string) => void;
    }[];
}) => {
    const matches = useMediaQuery('(min-width:1200px)');
    return FlightBar({ flightBar: details, iconVariant: matches ? 'full' : 'simplified', displayButtons });
};

export const useSelectedEvent = () => {
    const { selectedEvent } = useAppSelector(selectEvent);
    const { getAccessTypeByEventTypeId } = useEventSetting();
    const { typeId } = selectedEvent || {};
    const eventAccessType = getAccessTypeByEventTypeId(typeId);

    return { eventAccessType, selectedEvent };
};

export const useEventSetting = () => {
    const { eventSetting, selectedEvent } = useAppSelector(selectEvent);
    const { typeId, subTypeId } = selectedEvent || {};
    const getAccessTypeByEventTypeId = (eventTypeId: number) =>
        eventSetting.find((setting) => setting.id === eventTypeId)?.accessType;

    const getEventFieldsOfCurrentEvent = (): EventFields[] => {
        if (!typeId || !subTypeId) return null;
        const filterByType = eventSetting.find((item) => item.id === typeId);
        const filterBySubtype = filterByType?.subType.find((item) => item.id === subTypeId);
        return filterBySubtype?.eventFields || null;
    };
    return { eventSetting, getAccessTypeByEventTypeId, getEventFieldsOfCurrentEvent };
};
