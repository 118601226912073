import React, { useState } from 'react';
import { FormControlLabel, Radio, SxProps, Stack } from '@mui/material';

import HoverLabel from './HoverLabel';
import { ReactComponent as RadioIcon } from '../../assets/icons/Radio.svg';
import { ReactComponent as RadioIconChecked } from '../../assets/icons/RadioChecked.svg';
import COLORS from '../../style/color';

type RadioButtonProps = {
    label?: string;
    name: string;
    value: string;
    default?: boolean;
    selectedValue?: string;
    error?: boolean;
    sx?: SxProps;
    radioSx?: SxProps;
    handleOptionClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const StyledRadioButton = (props: RadioButtonProps) => {
    const { error = false, label, value, name, handleOptionClick, selectedValue, sx, radioSx } = props;
    const [showLabel, setShowLabel] = useState(false);

    return (
        <Stack sx={sx}>
            <FormControlLabel
                arial-name='radioButtonVal'
                value={value}
                control={
                    <Radio
                        icon={<RadioIcon stroke={error ? COLORS.error : COLORS.grayWhite} />}
                        checkedIcon={<RadioIconChecked />}
                        sx={radioSx}
                    />
                }
                label={name}
                key={value}
                onClick={handleOptionClick}
                checked={selectedValue === value}
                sx={{
                    margin: '0px',
                    paddingLeft: '12px',
                    paddingRight: '21px',
                    fontSize: '14px',
                    color: error ? COLORS.error : COLORS.grayDark,
                    '&:hover': {
                        backgroundColor: label ? COLORS.white : COLORS.buttonBgWhite,
                    },
                    ...sx,
                }}
                disableTypography={true}
                onMouseOver={() => setShowLabel(true)}
                onMouseOut={() => setShowLabel(false)}
            />
            {showLabel && label && <HoverLabel label={label} />}
        </Stack>
    );
};

export default StyledRadioButton;
