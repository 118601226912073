import React from 'react';
import { Stack } from '@mui/material';
import { FilledArrowDown, FilledArrowUp } from '../../Arrow';
import COLORS from '../../../../style/color';
import { SortDirection } from '../constants';

interface SortControlProps {
    direction: SortDirection | null | undefined;
    activeColor?: string;
    inactiveColor?: string;
    // if true, on change cycle includes null - no sorting specified.
    // i.e. Looping in sequence [SortDirection.DESC, SortDirection.ASC, null]
    nullable?: boolean;
    onChange?: (direction: SortDirection) => void;
}

const sortSequence = [SortDirection.DESC, SortDirection.ASC, null];

const SortControl = (props: SortControlProps) => {
    const {
        direction,
        activeColor = COLORS.grayDark,
        inactiveColor = COLORS.tableSortArrow,
        onChange,
        nullable = false,
    } = props;

    const onDirectionChange = () => {
        if (nullable) {
            const posOfSortSequence = sortSequence.indexOf(direction);
            onChange?.(
                posOfSortSequence >= sortSequence.length - 1 ? sortSequence[0] : sortSequence[posOfSortSequence + 1]
            );
        } else {
            onChange?.(direction === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC);
        }
    };

    return (
        <Stack sx={{ cursor: 'pointer', rowGap: 0.5 }} onClick={onDirectionChange}>
            <FilledArrowUp fill={direction === SortDirection.ASC ? activeColor : inactiveColor} />
            <FilledArrowDown fill={direction === SortDirection.DESC ? activeColor : inactiveColor} />
        </Stack>
    );
};

export { SortControl as default, SortControlProps };
